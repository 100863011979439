import { Controller } from "@hotwired/stimulus"
import log from 'loglevel';

export default class extends Controller {
  static targets = [ "form", "formContainer", "submitted", "inFlight", "resultContainer", "result" ]
  static values = { success: String }

  connect() {
    log.info("Controller: Call To Action loaded")
    this.grecaptchaSitekey = "6LemgJEgAAAAALjjFfnY4IArp2fytqxHqxJ8Vf4c"

    this.showForm()
  }

  postForm(event) {
    log.info("CTA Form Submitted")

    event.preventDefault();

    // Get the form data
    let formData = new FormData(this.formTarget)

    var promises = []

    if (formData.get("inquiry_type")) {
      promises.push(this.#submitInquiry(formData))
    }

    if (formData.get("newsletter")) {
      promises.push(this.#submitNewsletterSignUp(formData))
    }

    if (promises.length > 0) {
      // Add a 1 second promise so there's time to see our nice progress indicator
      promises.push(this.#delay(3000))

      this.#showInFlight()
    } else {
      this.#showError("Unable to submit this form - please try another.")
    }


    Promise.all(promises)
      .then(() => this.#showResult())
      .catch(error => this.#showError(`Error: ${error}`))
  }

  showForm() {
    this.#hideAllContainers()
    this.formContainerTargets.forEach(t => t.classList.remove("d-none"))
  }

  // #private methods

  #showInFlight() {
    this.#hideAllContainers()
    this.inFlightTargets.forEach(t => t.classList.remove("d-none"))
    this.#scrollResultIntoView()
  }

  #showResult() {
    this.#hideAllContainers()
    this.submittedTargets.forEach(t => t.classList.remove("d-none"))
    this.#scrollResultIntoView()
  }

  #showError(message) {
    this.resultTarget.innerHTML = message
    this.#showResult()
    log.error(message)
  }

  #scrollResultIntoView() {
    if (this.resultContainerTarget) {
      const elementTop = this.resultContainerTarget.getBoundingClientRect().y
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const top = scrollTop + elementTop - 100

      log.info(elementTop)
      log.info(scrollTop)
      log.info(top)

      window.scrollTo({top: top })

      // this.resultContainerTarget.scrollIntoView({ behavior: 'smooth', block: 'start', blockOffset: 50 });
      // log.info(this.resultContainerTarget.getBoundingClientRect())
      // window.scrollTo({top: -this.resultContainerTarget.getBoundingClientRect().y - 100 })
      // log.info(this.resultContainerTarget.getBoundingClientRect())
    }
  }

  #delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  #hideAllContainers() {
    this.formContainerTargets.forEach(t => t.classList.add("d-none"))
    this.inFlightTargets.forEach(t => t.classList.add("d-none"))
    this.submittedTargets.forEach(t => t.classList.add("d-none"))
  }

  async #submitInquiry(formData) {
    // Use a firebase function so they can deal with DOS attacks, etc. in stead of us
    let inquiry_url = "https://contactus-pvbwymdrdq-uc.a.run.app"

    log.info("Submitting contact inquiry")

    return this.#submitTo(inquiry_url, formData)
  }

  async #submitNewsletterSignUp(formData) {
    // Use a firebase function so they can deal with DOS attacks, etc. in stead of us
    let newsletter_url = "https://newslettersignup-pvbwymdrdq-uc.a.run.app"

    log.info("Signing up for newsltter")

    return this.#submitTo(newsletter_url, formData)
  }

  async #submitTo(url, formData) {
    // Check our honeypot
    this.#checkForSpam(formData)

    // Add the recaptcha response to the data
    formData.set("g-recaptcha-response", await this.#recpatchaResponse())

    // Convert the data to JSON
    let body = this.#bodyFrom(formData)

    // Post to our service
    let response = await this.#postJson(url, body)

    // Show the result in the logs
    log.info(await response.text())
  }

  async #recpatchaResponse() {
    if (typeof grecaptcha === 'undefined') {
      throw new Error("grecaptcha is not defined.  Make sure you have loaded the reCAPTCHA library.");
    }

    return new Promise((res, rej) => {
      grecaptcha.ready(() => {
        grecaptcha.execute(this.grecaptchaSitekey, {action: 'submit'})
          .then((token) => { return res(token) })
          .catch((error)=> { return rej(error) })
      })
    })
  }

  // Make sure 'email_confirm' field is empty (honeypot technique)
  #checkForSpam(formData) {
    if (formData.get('email_confirm')) {
      log.error('Spam detected');
      throw new Error("Spam detected");
    }
  }

  // Convert the form data to an object that we can send as JSON
  #bodyFrom(formData) {
    let data = {}

    formData.forEach((value,key) => data[key] = value)

    return JSON.stringify(data)
  }

  async #postJson(url, body) {
    return fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: body
    })
  }
}
