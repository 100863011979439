import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import log from 'loglevel';

export function initFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyAlbN1hHehLL89DOn1-v3FWS_Nv-vHo65U",
    authDomain: "allied-code-website.firebaseapp.com",
    projectId: "allied-code-website",
    storageBucket: "allied-code-website.appspot.com",
    messagingSenderId: "356332153368",
    appId: "1:356332153368:web:fbf534d09738ceb61c716d"
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  log.info("Firebase loaded");
}
