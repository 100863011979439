import { Controller } from "@hotwired/stimulus"
import { searchIndex } from "../../assets/search_index"; // Path to the generated search index
import lunr from "lunr";
import log from 'loglevel';

export default class extends Controller {
  static targets = ['query', 'results']

  connect() {
    log.info("Controller: Search loaded")
    this.#initializeIndex()
  }

  search(e) {
    e.preventDefault()

    const searchText = this.queryTarget.value.trim()

    if (searchText === "") {
      this.#clearResults();
      return
    }

    const searchResults = this.index.search(searchText)
    this.#displayResults(searchText, searchResults);
  }

  #initializeIndex() {
    this.index = lunr(function () {
      // Define the ref field, and the fields to index (search)
      this.ref("url");
      this.field("title");
      this.field("content");

      // Add documents to the index
      searchIndex.forEach(function (doc) {
        this.add(doc);
      }, this);
    });
  }

  #clearResults() {
    this.resultsTarget.innerHTML = "";
  }

  #displayResults(searchText, results) {
    const searchResultsContainer = this.resultsTarget;
    searchResultsContainer.innerHTML = "";

    if (results.length === 0) {
      searchResultsContainer.innerHTML = "<div class='list-group'><div class='list-group-item'>No results found</div></div>";
      return;
    }

    const resultList = document.createElement("div");
    resultList.classList.add("list-group");

    results.forEach((result) => {
      const listItem = document.createElement("a");
      listItem.href = result.ref + `?search=${encodeURIComponent(searchText)}`;
      listItem.textContent = this.#getPageTitle(result.ref);
      listItem.classList.add("list-group-item");
      listItem.classList.add("list-group-item-action");

      resultList.appendChild(listItem);
    });

    searchResultsContainer.appendChild(resultList);
  }

  #getPageTitle(url) {
    const page = searchIndex.find((doc) => doc.url === url);
    return page ? page.title : "";
  }
}
