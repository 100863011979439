import log from 'loglevel';

export function initFileFields() {
  const input = document.getElementById('mil-file-input');

  if (input) {
    const label = document.querySelector(".mil-custom-file-input");

    input.addEventListener("change", () => {
        const fileName = input.value.split('\\').pop();

        if (fileName.trim()) {
            label.classList.add("mil-with-file");
            label.innerText = fileName;
        }
    });
  }

  log.info("File fields loaded")
}
