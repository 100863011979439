import { Controller } from "@hotwired/stimulus"
import log from 'loglevel';

export default class extends Controller {
  static targets = ['form', 'button']
  static values = {
    defaultForm: String
  }


  connect() {
    log.info("Controller: Inquiry loaded")

    let hash = window.location.hash.substring(1)

    if (hash) {
      this.#showForm(hash)
    }else if(this.defaultFormValue) {
      this.#showForm(this.defaultFormValue)
    } else {
      this.#hideAllForms()
    }
  }

  // Show the selected form
  showForm(event) {
    this.#showForm(event.target.dataset.form)
  }

  #showForm(name) {
    log.info(`Showing ${name}`)

    this.#hideAllForms()
    let target = this.targets.findTarget(name)
    if (target) { target.classList.add('show') }

    this.#uncheckAllButtons()
    let button = this.targets.findTarget(`${name}-btn`)
    if (button) { button.checked = true }
  }

  #hideAllForms() {
    this.formTargets.forEach(t => {t.classList.remove('show')})
  }

  #uncheckAllButtons() {
    this.buttonTargets.forEach(t => {t.checked = false})
  }
}
