import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper/bundle"
import log from "loglevel"

export default class extends Controller {
  static targets = ["container", "prev", "next", "pagination"]
  static values = {
    slidesPerView: { type: Number, default: 1 },
    spaceBetween: { type: Number, default: 30 },
    speed: { type: Number, default: 800 },
    effect: { type: String, default: "slide" },
    parallax: Boolean,
    autoplay: Boolean,
    autoplayDelay: { type: Number, default: 5000 },
    loop: Boolean,
    slidesPerViewSm: Number,
    slidesPerViewMd: Number,
    slidesPerViewLg: Number
  }

  connect() {
    log.info("Controller: Swiper loaded")

    log.info(this.config())
    this.swiper = new Swiper(this.containerTarget, this.config())
  }

  config() {
    return {
      slidesPerView: this.slidesPerView(this.slidesPerViewValue),
      spaceBetween: this.spaceBetweenValue,
      speed: this.speedValue,
      navigation: this.navigation(),
      effect: this.effectValue,
      parallax: this.parallaxValue,
      autoplay: this.autoplay(),
      loop: this.loopValue,
      pagination: this.pagination(),
      breakpoints: this.breakpoints()
    }
  }

  navigation() {
    return {
      prevEl: this.hasPrevTarget && this.prevTarget,
      nextEl: this.hasNextTarget && this.nextTarget
    }
  }

  autoplay() {
    return this.autoplayValue ? { delay: this.autoplayDelayValue } : false
  }

  pagination() {
    return this.hasPaginationTarget && {
      el: this.paginationTarget,
      type: "bullets",
      clickable: true,
    }
  }

  breakpoints() {
    var value = {}
    if (this.hasSlidesPerViewSmValue) {
      value[768] = { slidesPerView: this.slidesPerView(this.slidesPerViewSmValue) }
    }

    if (this.hasSlidesPerViewMdValue) {
      value[992] = { slidesPerView: this.slidesPerView(this.slidesPerViewMdValue) }
    }

    if (this.hasSlidesPerViewLgValue) {
      value[1200] = { slidesPerView: this.slidesPerView(this.slidesPerViewLgValue) }
    }

    return value
  }

  // Convert 0 to 'auto'
  slidesPerView(num) {
    return num === 0 ? 'auto' : num
  }
}
