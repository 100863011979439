import log from 'loglevel';

export function initNavigationElements() {
  toggleMobileMenuOnNavClick();
  toggleTransparencyOnScroll();


  log.info("Navigation loaded")
}

function toggleMobileMenuOnNavClick() {
  const menuBtn = document.querySelector(".mil-menu-btn");

  if (menuBtn) {
    const navigation = document.querySelector('.mil-navigation');

    menuBtn.addEventListener("click", function () {
        this.classList.toggle('mil-active')
        if (navigation) { navigation.classList.toggle('mil-active') }
    });
  }
}

function toggleTransparencyOnScroll() {
  window.addEventListener("scroll", function () {
      const topPanel = document.querySelector(".mil-top-panel.mil-animated");
      const additionalPanel = document.querySelector(".has-additional-panel");

      if (window.scrollY >= 220) {
        if (topPanel) { topPanel.classList.remove("mil-top-panel-transparent") }
        if (additionalPanel) { additionalPanel.classList.add("mil-hide-top") }
      } else {
        if (topPanel) { topPanel.classList.add("mil-top-panel-transparent") }
        if (additionalPanel) { additionalPanel.classList.remove("mil-hide-top") }
      }
  });
}
