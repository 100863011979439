import { gsap } from "gsap";

export function initPlusAnimations() {
  document.addEventListener('mousemove', function(e) {
    document.querySelectorAll('.mil-plus-icon.animated').forEach(plus => {
      if (!plus.classList.contains('hovered')) {
        const pageHeight = window.innerHeight;
        const mouseYPercentage = (e.clientY / pageHeight) * 100;
        const rotationAngle = 45 - (90 * (mouseYPercentage / 100));
        gsap.to(plus, { duration: 0.05, rotation: rotationAngle, ease: "power1.inOut" });
      }
    });
  });

  document.querySelectorAll('.mil-plus-icon.animated').forEach(plus => {
    plus.addEventListener('mouseenter', function() {
      this.classList.add('hovered');

      const tl = gsap.timeline({defaults: {ease: "power1.inOut"}});
      tl.to(this, {scale: 1.5, rotation: 0, duration: 0.25})
        .to(this, {scale: 1, duration: 0.25});
    });
    plus.addEventListener('mouseleave', function() {
      this.classList.remove('hovered');
    });
  });
}
