import log from 'loglevel';

export function initTabs() {
  initTabButtons();
  initTabNavs();


  log.info("Tabs loaded")
}

function initTabButtons() {
  const tabButtons = Array.from(document.querySelectorAll(".mil-tab-buttons a"));

  tabButtons.forEach(button => {
      button.addEventListener("click", function () {
          tabButtons.forEach(btn => btn.classList.remove('mil-active'));
          this.classList.toggle('mil-active');
      });
  });
}

function initTabNavs() {
  const tabNavs = Array.from(document.querySelectorAll(".mil-tabs-left-nav a"));

  tabNavs.forEach(nav => {
      nav.addEventListener("click", function () {
          tabNavs.forEach(n => n.classList.remove('mil-active'));
          this.classList.toggle('mil-active');
      });
  });
}
