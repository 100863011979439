import "particles.js"
import log from 'loglevel';

export function initParticles() {
  document.querySelectorAll('.particles').forEach((element, index) => {
      element.id = `particles-${index}`;
      particlesJS.load(element.id, '/particles/nasa.json');
  });


  log.info("Particles loaded")
}
