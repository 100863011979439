import { Controller } from "@hotwired/stimulus"
import Mark from "mark.js"
import log from 'loglevel';

export default class extends Controller {
  static targets = ['searchField']

  connect() {
    log.info("Controller: Search Result loaded")

    const searchText = this.#getSearchText()

    if (searchText) {
      this.#highightSearchText(searchText)
      this.#scrollToFirstFoundText()
      this.#showSearchTextInSearchField(searchText)
    }
  }

  #getSearchText() {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get("search");

    return paramValue ? decodeURIComponent(paramValue) : null;
  }

  #highightSearchText(searchText) {
    const marker = new Mark(document.querySelectorAll(":not(script):not(style):not(meta):not(title):not(head)"));
    marker.mark(searchText, { separateWordSearch: false, className: "search-highlight" });
  }

  #scrollToFirstFoundText() {
    const firstFoundItem = document.querySelector(".search-highlight");

    if (firstFoundItem) {
      firstFoundItem.scrollIntoView({ behavior: "smooth", block: 'center' });
    }
  }

  #showSearchTextInSearchField(searchText) {
    this.searchFieldTarget.value = searchText
  }
}
