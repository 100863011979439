import c0 from './cta_controller';
import c1 from './inquiry_controller';
import c2 from './search_controller';
import c3 from './search_result_controller';
import c4 from './swiper_controller';
export const definitions = [
	{identifier: 'cta', controllerConstructor: c0},
	{identifier: 'inquiry', controllerConstructor: c1},
	{identifier: 'search', controllerConstructor: c2},
	{identifier: 'search-result', controllerConstructor: c3},
	{identifier: 'swiper', controllerConstructor: c4},
];
