import log from 'loglevel';

export function initAccordions() {
  const accordions = Array.from(document.getElementsByClassName("mil-accordion"));

  accordions.forEach(acc => {
      acc.addEventListener('click', function () {
          this.classList.toggle("mil-active");
          const panel = this.nextElementSibling;
          if (panel.style.maxHeight) {
              panel.style.maxHeight = null;
          } else {
              panel.style.maxHeight = `${panel.scrollHeight}px`;
          }
      });
  });

  log.info("Accordions initialized");
}
