import { initLoglevel } from "./initializers/loglevel"
import log from 'loglevel';
import { initAccordions } from "./initializers/accordions";
import { initNavigationElements } from "./initializers/navigation";
import { initTabs } from "./initializers/tabs";
import { initFileFields } from "./initializers/fileFields";
import { initParticles } from "./initializers/particles";
import { initFirebase } from "./initializers/firebase";
import { initPlusAnimations } from "./initializers/plusAnimations";

import { Application } from "@hotwired/stimulus"
import { definitions } from "stimulus:./controllers";

log.info("Allied Code is loading")

const stimulus = Application.start()
stimulus.load(definitions);

document.addEventListener("DOMContentLoaded", function () {
  initLoglevel();

  initAccordions();
  initNavigationElements();
  initTabs();
  initFileFields();
  initParticles();
  initFirebase();
  initPlusAnimations();

  log.info("Allied Code is fully loaded");
});
