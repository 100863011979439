import log from 'loglevel';

// Call this after the DOM is fully loaded - i.e. after window.BRIDGETOWN_ENV is set
export function initLoglevel() {
  // Set the current log level only if one has not been persisted and can’t be loaded.
  if (window.BRIDGETOWN_ENV === 'production') {
    log.setDefaultLevel(log.levels.ERROR);
  } else {
    log.setDefaultLevel(log.levels.DEBUG)
  }

  // Keep this around in case we need it from the console and to make access easier
  // NOTE: log is only available gobally after this is called (which is after the DOM is fully loaded)
  window.log = log
}
